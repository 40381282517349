
.current-openings-first-section-start-here, .current-openings-job-resume-section-start-here, .current-openings-button-section-start-here {
    padding: 0 1.2rem;
}

.current-openings-first-section-start-here, .current-openings-job-resume-section-start-here {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.current-openings-button-section-start-here {
    padding-bottom: 2.5rem;
}

.current-openings-image-div img {
    object-position: center;
    object-fit: cover;
}

.who-are-we-heading-and-para-div {
    padding-top: 3rem;
}

/* .current-openings-heading-and-para-div {
    position: relative;
} */

.current-openings-heading-and-para-first-inner-div {
    position: relative;
    top: 23%;
}

.current-openings-heading-and-para-div h1 {
    font-weight: 700;
    font-size: 35px;
    text-align: center;
    padding-bottom: 1.5rem;
    line-height: 65px;
}

.current-openings-heading-and-para-div h1 {
    color: rgba(187, 61, 39, 0.95);
}

.current-openings-heading-and-para-div p {
    color: rgba(0, 0, 0, 0.85);
    text-align: justify;
    line-height: 41px;
    font-weight: 600;
    font-size: 21px;
}

/* JOB Resume Section */
.current-openings-resume-title-div {
    /* border-radius: 5px; */
    background: rgba(187, 61, 39, 0.95);
    padding: 1rem 0rem 0.5rem 0;
    cursor: pointer;
}

.current-openings-resume-after-click-div {
    background: #D9D9D9;
    padding: 1rem 0rem 0.5rem 0;
    cursor: pointer;
}

.current-openings-resume-title-div h4 {
    text-align: center;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 21px;
}

.current-openings-resume-after-click-div h4 {
    text-align: center;
    color: rgba(187, 61, 39, 0.95);
    font-weight: 600;
    font-size: 21px;
}

.resume-responsibility, .resume-skills-required, .resume-qualifications {
    padding-top: 2.5rem;
}

.resume-responsibility ul li, .resume-skills-required ul li, .resume-qualifications ul li {
    font-weight: 600;
    line-height: 39px;
    color: rgba(0, 0, 0, 0.85);
    text-align: justify;
}

.current-openings-button-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.current-openings-button-div .join-the-tribe {
    border-radius: 5px;
    border: 3px solid rgba(187, 61, 39, 0.95);
    background: rgba(187, 61, 39, 0.95);
    color: #FFFFFF;
    padding: 0.6rem 3rem;
    font-weight: 800;
    font-size: 19px;
}

.current-openings-button-div .other-openings {
    border-radius: 5px;
    border: 1px solid rgba(187, 61, 39, 0.95);
    background: transparent;
    color: rgba(187, 61, 39, 0.95);
    padding: 0.6rem 2rem;
    font-weight: 600;
    font-size: 19px;
}

.other-openings span {
    margin-right: 1rem;
    font-weight: 500;
}

.resume-subjects-div, .requirements {
    margin-top: 2rem;
}

.resume-subjects-div h5, .requirements h5 {
    font-weight: 600;
}

/* Responsive Page CSS Start Here */
@media only screen and (max-width: 1199px) {
    .current-openings-heading-and-para-first-inner-div {
        position: unset;
        padding-top: 5rem;
    }
}
@media only screen and (max-width: 1058px) {
    .current-openings-heading-and-para-first-inner-div {
        padding-top: 1rem;
    }
}
@media only screen and (max-width: 991px) {
    .current-openings-image-div img {
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }
    .current-openings-job-resume-section-start-here {
        padding: 0 0rem;
    }
}
@media only screen and (max-width: 767px) {
    .current-openings-heading-and-para-div h1 {
        font-size: 29px;
    }
    .current-openings-heading-and-para-div p, .current-openings-resume-after-click-div h4, .current-openings-resume-title-div h4 {
        font-size: 18px;
    }
    .current-openings-button-div .join-the-tribe {
        padding: 0.4rem 2rem;
        font-size: 18px;
    }
    .current-openings-button-div .other-openings {
        padding: 0.5rem 1rem;
        font-size: 18px;
    }
}
@media only screen and (max-width: 575px) {
    .current-openings-heading-and-para-div h1 {
        padding-bottom: 0.5rem;
        line-height: 55px;
    }
    .resume-responsibility, .resume-skills-required, .resume-qualifications {
        padding: 2.5rem 2.2rem 1rem 2.2rem;
    }
    .current-openings-button-div .join-the-tribe {
        padding: 0.4rem 1.5rem;
        font-size: 17px;
    }
    .current-openings-button-div .other-openings {
        padding: 0.4rem 0.75rem;
        font-size: 17px;
    }
    .other-openings span {
        margin-right: 0.5rem;
    }
    .current-openings-first-section-start-here, .current-openings-job-resume-section-start-here, .current-openings-button-section-start-here {
        padding: 0 0.5rem;
    }
    .current-openings-job-resume-section-start-here {
        padding: 0 0rem;
    }
    .current-openings-resume-after-click-div, .current-openings-resume-title-div {
        padding: 1rem 1rem 0.5rem 1rem;
    }
    .current-openings-heading-and-para-div p, .current-openings-resume-after-click-div h4, .current-openings-resume-title-div h4 {
        font-size: 16px;
    }
    .resume-responsibility ul li, .resume-skills-required ul li, .resume-qualifications ul li {
        font-size: 16px;
    }
}
@media only screen and (max-width: 425px) {
    .current-openings-button-div {
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }
    .current-openings-button-div .other-openings {
        padding: 0.25rem 0.51rem;
        font-size: 16px;
        margin-top: 1rem;
    }
    .current-openings-button-div .join-the-tribe {
        font-size: 16px;
        padding: 0.2rem 1.1rem;
    }
    .resume-responsibility, .resume-skills-required, .resume-qualifications {
        padding: 1.5rem 1.2rem 1rem 1.2rem;
    }
}
@media only screen and (max-width: 375px) {
    .current-openings-resume-title-div h4 {
        font-size: 15px;
    }
}