
.join-the-tribe-section-start-here, .policy-for-permanent-employee-section-start-here, .freelancing-opportunities-section-start-here, .current-openings-section-start-here {
    padding: 0 1.2rem;
}

.join-the-tribe-section-start-here, .policy-for-permanent-employee-section-start-here, .freelancing-opportunities-section-start-here, .current-openings-section-start-here {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.join-the-tribe-image-div img {
    width: 100%;
    height: auto;
    object-position: center;
    object-fit: cover;
}

/* .join-the-tribe-heading-and-para-div {
    position: relative;
} */
  
.join-the-tribe-heading-and-para-first-inner-div {
    position: relative;
    top: 20%;
}

.join-the-tribe-heading-and-para-div h2, .policy-for-permanent-employee-heading-div h2, .freelancing-opportunities-heading-div h2, .current-openings-heading-div h2 {
    color: rgba(0, 0, 0, 0.7);
    font-weight: 700;
    font-size: 47px;
    line-height: 45px;
    text-align: center;
    padding-bottom: 1.5rem;
}

.join-the-tribe-heading-and-para-div h2 span, .policy-for-permanent-employee-heading-div h2 span, .freelancing-opportunities-heading-div h2 span, .current-openings-heading-div h2 span {
    color: rgba(187, 61, 39, 0.95);
}

.join-the-tribe-heading-and-para-div p {
    color: rgba(0, 0, 0, 0.85);
    text-align: justify;
    line-height: 41px;
    font-weight: 600;
    font-size: 21px;
}

.policy-for-permanent-employee-heading-div h2, .freelancing-opportunities-heading-div h2, .current-openings-heading-div h2 {
    padding-bottom: 2.5rem;
}

.policy-for-permanent-employee-card-div, .freelancing-opportunities-card-div {
    margin: 1rem 0;
}

.policy-for-permanent-employee-card-first-inner-div, .freelancing-opportunities-card-first-inner-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    background: #F8F8F8;
    padding: 2rem 1rem;
    height: 100%;
}

.policy-for-permanent-employee-card-div img, .freelancing-opportunities-card-div img {
    width: 55px;
    height: auto;
}

.policy-for-permanent-employee-card-div p, .freelancing-opportunities-card-div p {
    margin-bottom: 0rem;
    margin-top: 1rem;
    color: rgba(17, 36, 90, 0.95);
    font-weight: 600;
    text-align: center;
}

.current-openings-card-div {
    margin: 1rem 0;
    position: relative;
}

.current-openings-card-div a {
    text-decoration: none;
}

.developers-role-on-current-openings-page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.developers-role-on-current-openings-page p a {
    text-decoration: none;
    color: rgba(187, 61, 39, 0.95);
}

.developers-role-on-current-openings-page .backend-developers {
    margin-top: 2rem;
}

.current-openings-card-first-inner-div {
    background: #EFEFEF;
    padding: 2rem 1rem;
    height: 100%;
}

.current-openings-card-first-inner-div .qualification span, .current-openings-card-first-inner-div .subjects span {
    color: rgba(17, 36, 90, 0.95);
    font-weight: 700;
}

.current-openings-card-first-inner-div .qualification, .current-openings-card-first-inner-div .subjects, .developers-role-on-current-openings-page p {
    color: rgba(187, 61, 39, 0.95);
    font-weight: 600;
    font-size: 17px;
    line-height: 29px;
}

.current-openings-card-first-inner-div .subjects {
    text-align: justify;
    margin-top: 1rem;
}

.current-openings-card-first-inner-div h4 a {
    text-decoration: none;
    color: rgba(187, 61, 39, 0.95);
}

.current-openings-card-first-inner-div h4 {
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 2rem;
    line-height: 39px;
    color: rgba(187, 61, 39, 0.95);
}

.current-openings-card-first-inner-div h4 a:hover, .developers-role-on-current-openings-page p a:hover {
    border-bottom: 2px solid rgba(17, 36, 90, 0.95);
}

/* Current Openings with Hover Effect */
.current-openings-card-first-inner-div-with-hover-effect {
    background: rgba(187, 61, 39, 0.95);
    padding: 2rem 1rem;
    height: 100%;
}

.current-openings-card-first-inner-div-with-hover-effect h4 a {
    text-decoration: none;
    color: #FFFFFF;
}

.current-openings-card-first-inner-div-with-hover-effect h4 {
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 2rem;
    line-height: 39px;
    color: #FFFFFF;
}

.current-openings-card-first-inner-div-with-hover-effect h4 a:hover, .developers-role-on-current-openings-page-with-hover-effect p a:hover {
    border-bottom: 2px solid rgba(17, 36, 90, 0.95);
}

.current-openings-card-first-inner-div-with-hover-effect .qualification span, .current-openings-card-first-inner-div-with-hover-effect .subjects span {
    color: rgba(17, 36, 90, 0.95);
    font-weight: 700;
}

.current-openings-card-first-inner-div-with-hover-effect .qualification, .current-openings-card-first-inner-div-with-hover-effect .subjects, .developers-role-on-current-openings-page-with-hover-effect p {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 17px;
    line-height: 29px;
}

.current-openings-card-first-inner-div-with-hover-effect .subjects {
    text-align: justify;
    margin-top: 1rem;
}

.developers-role-on-current-openings-page-with-hover-effect {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.developers-role-on-current-openings-page-with-hover-effect p a {
    text-decoration: none;
    color: #FFFFFF;
}

.developers-role-on-current-openings-page-with-hover-effect .backend-developers {
    margin-top: 2rem;
}

/* Responsive Page CSS Start Here */
@media only screen and (max-width: 991px) {
    .join-the-tribe-heading-and-para-div h2, .policy-for-permanent-employee-heading-div h2, .freelancing-opportunities-heading-div h2, .current-openings-heading-div h2 {
        font-size: 41px;
    }
    .policy-for-permanent-employee-heading-div h2, .freelancing-opportunities-heading-div h2, .current-openings-heading-div h2 {
        padding-bottom: 1.5rem;
    }
    .join-the-tribe-heading-and-para-first-inner-div {
        position: unset;
    }
}
@media only screen and (max-width: 767px) {
    .join-the-tribe-heading-and-para-div h2, .policy-for-permanent-employee-heading-div h2, .freelancing-opportunities-heading-div h2, .current-openings-heading-div h2 {
        font-size: 35px;
    }
    .join-the-tribe-heading-and-para-div p {
        font-size: 18px;
    }
}
@media only screen and (max-width: 575px) {
    .current-openings-card-div {
        position: unset;
    }
    .developers-role-on-current-openings-page, .developers-role-on-current-openings-page-with-hover-effect {
        position: unset;
        text-align: center;
        transform: translate(0px);
    }
    .developers-role-on-current-openings-page-with-hover-effect p {
        font-weight: 600;
    }
    .join-the-tribe-section-start-here, .policy-for-permanent-employee-section-start-here, .freelancing-opportunities-section-start-here, .current-openings-section-start-here {
        padding: 0 0.5rem;
    }
    .policy-for-permanent-employee-heading-div h2, .freelancing-opportunities-heading-div h2, .current-openings-heading-div h2 {
        padding-top: 1.5rem;
    }
}