
.get-in-touch-section-start-here, .join-the-tribe-lets-connect-section-start-here {
    padding: 0 1.7rem;
}

.get-in-touch-section-start-here, .visit-us-section-start-here, .join-the-tribe-lets-connect-section-start-here {
    /* padding-top: 2.5rem; */
    padding-bottom: 2.5rem;
}

.join-the-tribe-lets-connect-section-start-here {
    padding-top: 2.5rem;
}

.get-in-touch-heading-div {
    padding: 2.5rem 0;
}

.visit-us-section-start-here {
    padding-top: 1.5rem;
}

.get-in-touch-heading-div h2, .visit-us-map-heading-div h2, .join-the-tribe-lets-connect-heading-and-para-div h2 {
    color: rgba(0, 0, 0, 0.7);
    font-weight: 700;
    font-size: 47px;
    line-height: 45px;
    text-align: center;
}

.visit-us-map-heading-div h2 {
    padding-bottom: 2rem;
}

.join-the-tribe-lets-connect-heading-and-para-div h2 {
    padding-bottom: 1.5rem;
}

.get-in-touch-heading-div h2 span, .visit-us-map-heading-div h2 span, .join-the-tribe-lets-connect-heading-and-para-div h2 span {
    color: rgba(187, 61, 39, 0.95);
}

.get-in-touch-form-div label {
    position: relative;
    border-radius: 8px;
    background: #FFF;
    padding: 0.2rem 1rem;
    top: 19px;
    left: 1.3rem;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.85);
}

.get-in-touch-form-div input, .get-in-touch-form-div select, .get-in-touch-form-div textarea {
    border: 2px solid rgba(187, 61, 39, 0.95);
    outline: none;
    padding: 1rem 1rem;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    cursor: pointer;
}

.get-in-touch-form-div select option {
    color: rgba(187, 61, 39, 0.95) !important;
    font-weight: 600;
    font-size: 17px;
}

.get-in-touch-form-div input:focus, .get-in-touch-form-div select:focus, .get-in-touch-form-div textarea:focus {
    box-shadow: none;
    border: 2px solid rgba(187, 61, 39, 0.95);
}

input[type=file] {
    color: rgba(187, 61, 39, 0.95);
}

.get-in-touch-button-div button {
    border-radius: 5px;
    border: 3px solid rgba(187, 61, 39, 0.95);
    background: rgba(187, 61, 39, 0.95);
    color: #FFF;
    font-weight: 800;
    font-size: 20px;
    padding: 0.5rem 3.5rem;
    margin-top: 0.5rem;
}

.visit-us-map-div {
    padding-right: 0rem;
    padding-left: 0rem;
}

.visit-us-map-heading-div p {
    text-align: center;
    padding-bottom: 1.5rem;
}

.visit-us-map-heading-div .location-icon img {
    width: 27px;
    height: auto;
    margin-right: 1rem;
}

.visit-us-map-heading-div .location-para {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 17px;
    line-height: 33px;
}

.join-the-tribe-lets-connect-heading-and-para-div {
    padding-top: 3rem;
}

.join-the-tribe-lets-connect-heading-and-para-div p span img {
    width: 35px;
    height: auto;
}

.join-the-tribe-lets-connect-heading-and-para-div p {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 19px;
    margin-top: 2rem;
}

.join-the-tribe-lets-connect-heading-and-para-div .landline-para, .join-the-tribe-lets-connect-heading-and-para-div .mobile-para, .join-the-tribe-lets-connect-heading-and-para-div .email-para {
    margin-left: 1.5rem;
}

.join-the-tribe-lets-connect-image-div img {
    width: 100%;
    height: 500px;
    object-position: center;
    object-fit: cover;
}

.form-select {
    --bs-form-select-bg-img: url("../../Assets/Touchbase/select-icon.png");
}

/* Responsive Page CSS Start Here */
@media only screen and (max-width: 767px) {
    .get-in-touch-heading-div h2, .visit-us-map-heading-div h2, .join-the-tribe-lets-connect-heading-and-para-div h2 {
        font-size: 35px;
    }
    .get-in-touch-heading-div {
        padding: 2.5rem 0 1rem 0;
    }
    .get-in-touch-form-div input, .get-in-touch-form-div select, .get-in-touch-form-div textarea {
        font-size: 16px;
        padding: 0.6rem;
    }
    .join-the-tribe-lets-connect-heading-and-para-div p {
        font-size: 17px;
        margin-top: 1rem;
    }
    .join-the-tribe-lets-connect-heading-and-para-div p span img {
        width: 27px;
    }
}
@media only screen and (max-width: 576px) {
    .join-the-tribe-lets-connect-image-div img {
        height: 321px;
    }
    .get-in-touch-section-start-here, .join-the-tribe-lets-connect-section-start-here {
        padding: 0 0.5rem;
    }
}
