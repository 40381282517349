
.events-inner-pages-button-and-image-section-start-here {
    padding: 2.5rem 0rem;
}

.events-inner-pages-button-div {
    background: rgba(187, 61, 39, 0.95);
    height: auto;
    padding: 1rem 0rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
    /* padding-left: 0rem; */
}

.events-inner-pages-button-div h4 {
    margin: 4rem 0;
    font-size: 29px;
}

.click-events-inner-pages {
    background: #FFFFFF;
    padding: 1rem 5rem;
    border-radius: 40px 0px 0px 40px;
    color: rgba(187, 61, 39, 0.95);
    font-weight: 700;
    cursor: pointer;
}

.without-click-events-inner-pages {
    color: #FFFFFF;
    font-weight: 700;
    padding: 1rem 5rem;
    cursor: pointer;
}

/* Events per Year Images CSS Start Here */
.events-inner-pages-image-div {
    padding: 0 0.5rem;
}

div.events-inner-pages-image-div {
    height: 135vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.events-inner-pages-banner-images-div {
    margin: 0rem 0 1.5rem 0;
}

.events-inner-pages-banner-images-first-inner-div, .csr-events-inner-pages-banner-images-first-inner-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(238, 238, 238, 0.93);
    border: 8px;
    padding: 0rem 1.5rem 1.5rem 1.5rem;
    height: 100%;
}

.events-inner-pages-banner-images-div h4 {
    text-align: center;
    padding-top: 1rem;
    font-weight: 700;
    font-size: 23px;
    line-height: 31px;
}

.events-inner-pages-banner-images-div h4 a {
    text-decoration: none;
    color: rgba(187, 61, 39, 0.95);
}

/* Responsive Page CSS Start Here */
@media only screen and (max-width: 910px) {
    .click-events-inner-pages {
        border-radius: 0px;
        border-bottom: 3px solid rgba(187, 61, 39, 0.95);
        border-right: 3px solid rgba(187, 61, 39, 0.95);
        border-top: 3px solid rgba(187, 61, 39, 0.95);
        box-shadow: 0 4px 28px rgba(0,0,0,.25);
    }
    .events-inner-pages-button-div h4 {
        text-align: center;
    }
    .events-inner-pages-button-div {
        align-items: normal;
    }
    .click-events-inner-pages, .without-click-events-inner-pages {
        padding: 1rem 0rem;
    }
}
@media only screen and (max-width: 767px) {
    .events-inner-pages-button-div h4 {
        font-size: 25px;
        text-align: center;
        margin: 3rem 0;
    }
    .click-events-inner-pages, .without-click-events-inner-pages {
        padding: 0.8rem 0rem;
    }
    .events-inner-pages-banner-images-div h4 {
        font-size: 20px;
        line-height: 31px;
    }
}
@media only screen and (max-width: 375px) {
    .events-inner-pages-button-div h4 {
        font-size: 21px;
    }
    .events-inner-pages-banner-images-div h4 {
        font-size: 18px;
    }
}