
/* .footer-section-of-hl {
    background: cadetblue;
} */

.footer-logo-and-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.footer-content p {
    text-align: justify;
    margin-bottom: 0;
    margin-left: -41px;
    line-height: 29px;
    color: rgba(113, 113, 122, 1);
    font-weight: 600;
    font-size: 15px;
}

.footer-logo img {
    width: 300px;
    height: auto;
    margin-left: -21px;
}

.footer-nav-menu-and-social-icon, .footer-nav-menu-main-div, .first-three-footer-menu ul, .last-three-footer-menu ul, .footer-social-media-icon-div ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.first-three-footer-menu ul, .last-three-footer-menu ul, .footer-social-media-icon-div ul {
    list-style: none;
    padding-left: 0rem;
    margin-bottom: 0rem;
}

.first-three-footer-menu ul li, .last-three-footer-menu ul li, .footer-social-media-icon-div ul li {
    margin: 0 0.4rem;
}

.first-three-footer-menu ul li a, .last-three-footer-menu ul li a {
    text-decoration: none;
    color:rgba(9, 9, 20, 1);
    font-weight: 600;
    font-size: 16px;   
}

.footer-last-div-content p {
    text-align: center;
    color: rgba(9, 9, 20, 1);
    font-size: 16px;
    font-weight: 600;
    margin: 2rem 0 1rem 0
}

.footer-last-div-content p span a {
    color: rgba(9, 9, 20, 1);
}

/* Responsive Footer CSS Start Here */
@media only screen and (max-width: 1297px) {
    .footer-nav-menu-and-social-icon {
        justify-content: center;
        align-items: center;
    }
}
@media only screen and (max-width: 1126px) {
    .last-three-footer-menu ul li {
        margin: 0.7rem 0.4rem;
    }
    .footer-content p {
        margin-left: -7px;
    }
    .footer-logo img {
        margin-left: -7px;
    }
}
@media only screen and (max-width: 850px) {
    .footer-content p {
        line-height: 27px;
    }
    .first-three-footer-menu ul li a, .last-three-footer-menu ul li a {
        font-size: 15px;
    }
}
@media only screen and (max-width: 767px) {
    .footer-section-of-hl {
        padding: 0 1.2rem;
    }
    .footer-content p {
        line-height: 35px;
    }
    .footer-logo img {
        margin-left: -25px;
        width: 175px;
    }
    .footer-social-media-icon-div ul li a img {
        width: 25px;
        height: auto;
    }
    .footer-nav-menu-and-social-icon {
        justify-content: space-between;
    }
}
@media only screen and (max-width: 658px) {
    .footer-nav-menu-and-social-icon {
        justify-content: center;
    }
}
@media only screen and (max-width: 608px) {
    .footer-logo img {
        width: 315px;
    }
    .footer-content p {
        line-height: 29px;
    }
    .footer-social-media-icon-div ul li a img {
        width: 25px;
        height: auto;
    }
    .footer-last-div-content p {
        line-height: 33px;
    }
}
@media only screen and (max-width: 500px) {
    .footer-section-of-hl {
        padding: 0 0.5rem;
    }
    .first-three-footer-menu ul, .last-three-footer-menu ul, .footer-social-media-icon-div ul {
        padding-top: 0.4rem;
    }
    .first-three-footer-menu ul li, .last-three-footer-menu ul li, .footer-social-media-icon-div ul li {
        margin: 0.4rem 0.8rem;
    }
    .footer-logo-and-content {
        flex-direction: column;
    }
    .footer-logo img {
        margin-left: 0px;
        width: 191px;
    }
    .footer-content p {
        padding: 0 1rem 1rem 1rem;
    }
}
@media only screen and (max-width: 425px) {
    .first-three-footer-menu ul li, .last-three-footer-menu ul li, .footer-social-media-icon-div ul li {
        margin: 0.4rem 0.4rem;
    }
}