
.musings-heading-div {
    padding: 3rem 0;
}

.musings-quote-main-div {
    margin: 1.5rem 0;
    padding: 0rem;
}

.musings-quote-first-inner-div {
    background: #D9D9D9;
    padding: 0.5rem 2rem;
    height: 100%;
}

.musings-heading-div h2 {
    color: rgba(0, 0, 0, 0.7);
    font-weight: 700;
    font-size: 47px;
    line-height: 45px;
    text-align: center;
    /* padding-bottom: 1.5rem; */
}

.musings-heading-div h2 span {
    color: rgba(187, 61, 39, 0.95);
}

.musings-quote-date-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.musings-quote-date-div p {
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
    line-height: 39px;
    font-weight: 700;
}

.musings-quotes-div {
    margin: 3rem 0 4rem 0;
}

.musings-quotes-div h2 {
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
    font-size: 47px;
    line-height: 83px;
}

.musings-written-by-and-tribe-member-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.musings-written-by-and-tribe-member-div p {
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
    line-height: 39px;
    font-weight: 700;
}

/* Responsive CSS page Start Here */
@media only screen and (max-width: 767px) {
    .musings-heading-div h2, .musings-quotes-div h2 {
        line-height: 45px;
        font-size: 35px;
    }
    .musings-quotes-div h2 {
        line-height: 59px;
    }
}
@media only screen and (max-width: 575px) {
    .musings-quotes-div h2 {
        font-size: 29px;
        line-height: 49px;
    }
    .musings-written-by-and-tribe-member-div p, .musings-quote-date-div p {
        font-size: 16px;
        margin-bottom: 5px;
    }
    .musings-written-by-and-tribe-member-div {
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
}