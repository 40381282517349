
header {
    background: #FFFFFF;
}

.nav-toggle-button {
    display: none;
}
  
.nav-toggle-button span i {
    font-size: 33px;
    cursor: pointer;
    font-weight: 800;
    line-height: 43px;
}

.nav-menu-of-hl .active {
    color: rgba(187, 61, 39, 0.95) !important;
}

.main-header-of-hl {
    padding: 0.2rem 1.2rem;
}

.main-navbar-of-hl {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-of-hl img {
    width: 325px;
    height: auto;
}

.nav-menu-of-hl ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0rem;
    margin-bottom: 0rem;
}

.nav-menu-of-hl ul li {
    margin: 0 1.5rem;
}

.nav-menu-of-hl ul li a {
    text-decoration: none;
    color: rgba(17, 36, 90, 0.95);
    font-weight: 600;
    font-size: 17px;
}

/* Responsive Navbar CSS Start Here */
@media only screen and (max-width: 1240px) {
    .nav-menu-of-hl ul li {
        margin: 0 1.2rem;
    }
}
@media only screen and (max-width: 1224px) {
    .logo-of-hl img {
        width: 386px;
    }
}
@media only screen and (max-width: 1200px) {
    .nav-menu-of-hl ul li {
        margin: 0 1rem;
    }
}
@media only screen and (max-width: 1182px) {
    .nav-menu-of-hl ul li {
        margin: 0 1rem;
    }
}
@media only screen and (max-width: 1162px) {
    .nav-menu-of-hl ul li {
        margin: 0 0.7rem;
    }
}
@media only screen and (max-width: 1144px) {
    .logo-of-hl img {
        width: 386px;
    }
    .nav-menu-of-hl ul li a {
        font-size: 16px;
    }
}
@media only screen and (max-width: 1076px) {
    .nav-menu-of-hl ul li {
        margin: 0 0.7rem;
    }
    .logo-of-hl img {
        width: 364px;
    }
}
@media only screen and (max-width: 1008px) {
    .logo-of-hl img {
        width: 311px;
    }
}
@media only screen and (max-width: 991px) {
    .nav-menu-of-hl {
        width: 100%;
    }
    .main-navbar-of-hl, .nav-menu-of-hl ul {
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
    }
    .nav-menu-of-hl ul li {
        margin: 0.5rem 0.7rem;
        width: 100%;
        border-bottom: 1px solid rgba(187, 61, 39, 0.95);
        padding-bottom: 1rem;
    }
    .nav-menu-of-hl ul li a {
        font-size: 17px;
    }
    .nav-menu-of-hl ul li:first-of-type {
        padding-top: 1rem;
    }
    .nav-menu-of-hl ul li:last-of-type {
        border-bottom: none;
    }
    .nav-toggle-button {
        display: block;
        position: absolute;
        right: 3rem;
        margin-top: 1rem;
    }
    .nav-un-ordered-list {
        height: 0;
        overflow: hidden;
        transition: all 0.3s linear;
    }
    .show-nav-un-ordered-list {
        height: 20.5rem;
        padding-top: 0.5rem;
    }
}
@media only screen and (max-width: 767px) {
    .nav-toggle-button span i {
        font-size: 30px;
    }
}
@media only screen and (max-width: 500px) {
    .logo-of-hl img {
        width: 215px;
    }
    .nav-toggle-button span i {
        line-height: 27px;
        font-size: 25px;
    }
    .nav-menu-of-hl ul li a {
        font-size: 16px;
    }
    .nav-toggle-button {
        margin-top: 0.7rem;
    }
}
@media only screen and (max-width: 375px) {
    .nav-toggle-button span i {
        line-height: 21px;
        font-size: 22px;
    }
    .nav-toggle-button {
        right: 1.3rem;
        margin-top: 0.85rem;
    }
}