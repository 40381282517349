
.products-and-services-heading-div h1 {
    color: rgba(0, 0, 0, 0.7);
    font-weight: 700;
    font-size: 47px;
    line-height: 45px;
    text-align: center;
    padding: 3rem 0;
}

.products-and-services-heading-div h1 span {
    color: rgba(187, 61, 39, 0.95);
}

.product-image-div, .service-image-div {
    background-image: url("../../Assets/Products_and_Services/background.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.product-image-div img, .service-image-div img {
    width: 800px;
    height: auto;
    object-fit: cover;
    object-position: center;
    display: flex;
    margin: 0 auto;
}

.service-content-div, .product-content-div {
    padding: 5rem 3rem;
}

.service-content-div p, .product-content-div p {
    color: rgba(0, 0, 0, 0.85);
    text-align: justify;
    line-height: 41px;
    font-weight: 600;
    font-size: 21px;
}

.service-content-div span img, .product-content-div span img {
    width: 100px;
    height: auto;
    object-fit: cover;
    margin-top: -2rem;
    object-position: center;
}

.service-content-div span img {
    width: 125px;
}

.service-content-div button, .product-content-div button {
    border: none;
    outline: none;
    background: rgba(187, 61, 39, 0.95);
    color: #FFFFFF;
    font-weight: 700;
    border-radius: 11px;
    padding: 0.5rem 2rem;
    font-size: 21px;
    margin-top: 1rem;
}

/* Responsive Page CSS Start Here */
@media only screen and (max-width: 767px) {
    .products-and-services-heading-div h1 {
        font-size: 35px;
        padding: 2rem 0;
    }
    .service-content-div p, .product-content-div p {
        font-size: 17px;
    }
    .service-content-div button, .product-content-div button {
        font-size: 18px;
    }
    .service-content-div, .product-content-div {
        padding: 4rem 1.2rem;
    }
}