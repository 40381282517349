.meet-the-tribe-section-start-here,
.meet-our-tribe-chief-section-start-here,
.meet-our-tribe-clan-section-start-here {
  padding: 2.5rem 1.2rem 2.5rem 1.2rem;
}

.meet-the-tribe-heading-and-para-div {
  position: relative;
}

.meet-the-tribe-heading-and-para-first-inner-div {
  position: absolute;
  top: 27%;
}

.meet-the-tribe-heading-and-para-first-inner-div h2,
.meet-our-tribe-chief-heading-div h2,
.meet-our-tribe-clan-heading-div h2 {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 700;
  font-size: 47px;
  line-height: 45px;
  text-align: center;
  padding-bottom: 1.5rem;
}

.meet-the-tribe-heading-and-para-first-inner-div h2 span,
.meet-our-tribe-clan-heading-div h2 span {
  color: rgba(187, 61, 39, 0.95);
}

.meet-the-tribe-heading-and-para-first-inner-div p {
  color: rgba(0, 0, 0, 0.85);
  text-align: justify;
  line-height: 41px;
  font-weight: 600;
  font-size: 21px;
}

.meet-the-tribe-image-div img {
  width: 100%;
  height: 400px;
  object-position: center;
  object-fit: cover;
}

.meet-our-tribe-chief-heading-div h2 {
  color: rgba(187, 61, 39, 0.95);
}

/* The flip box container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */

.meet-our-tribe-chief-first-div {
  display: flex;
  justify-content: center;
  margin: 1rem 3rem;
}

.meet-our-tribe-clan-first-div {
  display: flex;
  justify-content: center;
  margin: 1rem 0rem;
}

.flip-box {
  background-color: transparent;
  width: 384px;
  height: 455px;
  flex-shrink: 0;
  perspective: 1000px;
}

.meet-our-tribe-clan-flip-box {
  background-color: transparent;
  width: 275px;
  height: 365px;
  flex-shrink: 0;
  perspective: 1000px;
}

/* This container is needed to position the front and back side */
.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-box:hover .flip-box-inner,
.meet-our-tribe-clan-flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0 auto;
  backface-visibility: hidden;
  padding: 2rem;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 70px 0;
}

.heading-para-div-for-front h4 {
  color: #000;
  font-weight: 700;
  padding-top: 0.8rem;
}

.heading-para-div-for-front p {
  color: rgba(17, 36, 90, 0.95);
  font-weight: 600;
  margin-bottom: 0rem;
}

.flip-box-front img,
.flip-box-back img {
  border-radius: 70px 0;
}

.flip-box-front img {
  filter: grayscale(100%);
}

/* Style the front side (fallback if image is missing) */
.flip-box-front {
  background: rgba(238, 238, 238, 0.93);
  color: black;
}

/* Style the back side */
.flip-box-back {
  background: rgba(238, 238, 238, 0.93);
  color: #000;
  transform: rotateY(180deg);
}

.meet-our-tribe-clan-heading-div h2 {
  padding: 2rem 0 3.5rem 0;
}

/* Responsive CSS Code Start Here */
@media only screen and (max-width: 1199px) {
  .meet-our-tribe-chief-first-div {
    margin: 1rem 1rem;
  }
  .meet-our-tribe-clan-flip-box {
    width: 301px;
    height: 399px;
  }
}
@media only screen and (max-width: 991px) {
  .flip-box {
    width: 311px;
    height: 381px;
  }
  .meet-our-tribe-chief-first-div {
    margin: 1rem 0rem;
  }
  .meet-the-tribe-heading-and-para-first-inner-div h2,
  .meet-our-tribe-chief-heading-div h2,
  .meet-our-tribe-clan-heading-div h2 {
    font-size: 41px;
  }
  .meet-our-tribe-clan-flip-box {
    width: 261px;
    height: 349px;
  }
  .meet-the-tribe-heading-and-para-first-inner-div {
    position: unset;
  }
}
@media only screen and (max-width: 850px) {
  .meet-our-tribe-clan-flip-box {
    width: 237px;
    height: 319px;
  }
}
@media only screen and (max-width: 767px) {
  .flip-box {
    width: 245px;
    height: 305px;
  }
  .meet-our-tribe-clan-flip-box {
    width: 275px;
    height: 345px;
  }
  .meet-the-tribe-heading-and-para-first-inner-div h2,
  .meet-our-tribe-chief-heading-div h2,
  .meet-our-tribe-clan-heading-div h2 {
    font-size: 35px;
  }
  .meet-the-tribe-heading-and-para-first-inner-div p {
    font-size: 18px;
    line-height: 39px;
  }
}
@media only screen and (max-width: 600px) {
  .meet-our-tribe-clan-flip-box {
    width: 245px;
    height: 305px;
  }
}
@media only screen and (max-width: 575px) {
  .flip-box,
  .meet-our-tribe-clan-flip-box {
    width: 301px;
    height: 351px;
  }
  .meet-the-tribe-image-div img {
    height: 321px;
}
.meet-the-tribe-section-start-here, .meet-our-tribe-chief-section-start-here, .meet-our-tribe-clan-section-start-here {
  padding: 2.5rem 0.5rem 2.5rem 0.5rem;
}
}
