/* Blog Carousel CSS Start Here */
.container.carouselSliderFirstInnerDiv {
    padding: 0rem 0;
}

.carousel_slider_section {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 3rem;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide.swiperSlide {
    background-position: center;
    background-size: cover;
    width: 501px;
    height: 301px;
}

.swiper-slide.swiperSlide {
    position: relative;
    padding: 40px;
    filter: blur(2px);
}

.swiperSlide img {
    position: absolute;
    /* width: 100%; */
    height: 100%;
    left: 0;
    top: 0;
    /* border-radius: 13px; */
    object-fit: contain;
}

.swiper-slide-img img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide.swiper-slide-visible.swiper-slide-active {
    filter: blur(0px) !important;
}

.swiper-3d .swiper-slide-shadow-right, .swiper-3d .swiper-slide-shadow-left {
    background-image: none !important;
}

.blog-heading-and-para-div .christmas-heading {
    color: green;
}

.blogs-heading-and-para-section-for-banner-class {
    background-image: url("../../../../../Assets/Events/2023/Christmas_Day_2023/Christmas_Day.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
/* Blog Carousel CSS End Here */

/* Blogs Para and Heading CSS Start Here */
.blogs-heading-and-para-section-start-here {
    padding: 0rem 1.5rem 2.5rem 1.5rem;
}

.blog-heading-and-para-div h1 {
    text-align: center;
    padding: 2rem 0;
    color: rgba(187, 61, 39, 0.95);
    font-weight: 700;
    font-size: 41px;
    line-height: 65px;
}

.blog-heading-and-para-div h2 {
    padding-bottom: 1rem;
    color: rgba(187, 61, 39, 0.95);
    font-weight: 600;
    line-height: 45px;
}

.blog-heading-and-para-div p {
    text-align: justify;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 19px;
    line-height: 39px;
    margin-bottom: 2rem;
}

.blog-writer-div p {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    margin-bottom: 0rem;
}

.blog-button-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.blog-button-div button {
    border-radius: 5px;
    border: 2px solid rgba(187, 61, 39, 0.95);
    color: rgba(187, 61, 39, 0.95);
    font-weight: 600;
    background: transparent;
    padding: 0.5rem 2rem;
    font-size: 20px;
}

.blog-button-div .icon {
    margin-right: 1rem;
}

/* Birthday Celebration Page CSS Start Here */
.blog-yearly-birthday-celebration-div {
    margin: 1.5rem 0;
}

.blog-yearly-birthday-celebration-first-inner-div {
    border-radius: 8px;
    background: rgba(238, 238, 238, 0.93);
    padding: 0 1.5rem 1.5rem 1.5rem;
}

.blog-yearly-birthday-celebration-first-inner-div img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1.3rem;
}

.blog-yearly-birthday-celebration-first-inner-div h4 {
    padding: 1.5rem 0 1rem 0;
    font-weight: 600;
    font-size: 21px;
    color: rgba(187, 61, 39, 0.95);
    letter-spacing: 2px;
}

.blog-yearly-birthday-celebration-first-inner-div p {
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 17px;
}

/* Responsive Page CSS Start Here */
@media only screen and (max-width: 767px) {
    .blog-heading-and-para-div h1 {
        font-size: 35px;
    }
    .blog-button-div button {
        padding: 0.3rem 1rem;
    }
    .container.carouselSliderFirstInnerDiv {
        max-width: 100%;
    }
    .swiperSlide img {
        width: 100%;
    }
    .swiperSlide .nexus {
        border: none;
        border-radius: 0px;
    }
    .swiper-slide.swiperSlide {
        width: 351px;
        height: 301px;
    }
    .carousel_slider_section {
       padding: 1rem;
    }
}
@media only screen and (max-width: 700px) {
    .blog-writer-div p {
        font-size: 21px;
    }
    .blog-button-div button {
        font-size: 17px;
    }
}
@media only screen and (max-width: 575px) {
    .blogs-heading-and-para-section-start-here {
        padding: 0rem 0.5rem 2.5rem 0.5rem;
    }
    .blog-heading-and-para-div h1 {
        font-size: 29px;
        line-height: 49px;
    }
    .blog-heading-and-para-div p {
        font-size: 17px;
        margin-bottom: 1.5rem;
    }
    .blog-heading-and-para-div h2 {
        font-size: 23px;
    }
    .blog-writer-div p {
        font-size: 19px;
    }
    .blog-button-div {
        justify-content: flex-start;
        padding-top: 1rem;
    }
}
@media only screen and (max-width: 499px) {
    .swiper-slide.swiperSlide {
        width: 100%;
    }
}
@media only screen and (max-width: 425px) {
    .blog-heading-and-para-div h1 {
        padding: 0rem 0 2rem 0;
    }
}