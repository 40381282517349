
.who-are-we-section-on-know-us-page-start-here, .what-we-do-section-on-know-us-page-start-here, .people-who-contribute-section-start-here {
    padding: 3rem 1.2rem;
}

.who-are-we-on-know-us-page-image-div img, .what-we-do-on-know-us-page-image-div img {
    width: 100%;
    height: 500px;
    object-position: center;
    object-fit: cover;
}

.who-are-we-on-know-us-page-heading-and-para-div h2, .what-we-do-on-know-us-page-heading-and-para-div h2, .people-who-contribute-heading-div h2 {
    color: rgba(0, 0, 0, 0.7);
    font-weight: 700;
    font-size: 47px;
    line-height: 45px;
    text-align: center;
    padding-bottom: 1.5rem;
}

.who-are-we-on-know-us-page-heading-and-para-div h2 span, .what-we-do-on-know-us-page-heading-and-para-div h2 span, .know-us-quote-content-div h3 span, .people-who-contribute-heading-div h2 span {
    color: rgba(187, 61, 39, 0.95);
}

.who-are-we-on-know-us-page-heading-and-para-div p, .what-we-do-on-know-us-page-heading-and-para-div p {
    color: rgba(0, 0, 0, 0.85);
    text-align: justify;
    line-height: 41px;
    font-weight: 600;
    font-size: 21px;
}

.know-us-quote-section-start-here {
    background-image: url("../../Assets/Know_Us/know-us-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0rem 1.2rem;
}

.know-us-quote-image-div img {
    width: 100%;
    height: auto;
    object-position: center;
    object-fit: cover;
}

.know-us-quote-content-div {
    position: relative;
}

.know-us-quote-content-first-inner-div {
    position: absolute;
    top: 40%;
    left: 10%;

}

.know-us-quote-content-div h3 {
    color: rgba(0, 0, 0, 0.7);
    font-weight: 700;
    font-size: 45px;
    line-height: 59px;
}

.know-us-quote-content-div h3, .know-us-quote-content-div p {
    text-align: right;
}

.know-us-quote-content-div p {
    color: rgba(187, 61, 39, 0.95);
    font-size: 25px;
    font-weight: 600;
    margin-top: 1rem;
}

.people-who-contribute-first-inner-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #D9D9D9;
    padding: 2rem 0 1rem 0;
}

.people-who-contribute-image-div img {
    filter: grayscale(100%);
}

.people-who-contribute-heading-div h2 {
    padding-bottom: 3rem;
}

.people-who-contribute-first-inner-div a {
    text-decoration: none;
}

.people-who-contribute-first-inner-div p {
    color: #11245A;
    margin-top: 1rem;
    font-weight: 600;
    font-size: 18px;
}

/* Responsive Know Us CSS Start Here */
@media only screen and (max-width: 991px) {
    .who-are-we-on-know-us-page-image-div img, .what-we-do-on-know-us-page-image-div img {
        height: 551px;
    }
}
@media only screen and (max-width: 767px) {
    .who-are-we-on-know-us-page-heading-and-para-div h2, .what-we-do-on-know-us-page-heading-and-para-div h2, .people-who-contribute-heading-div h2 {
        line-height: 45px;
        font-size: 35px;
        padding-bottom: 1rem;
    }
    .who-are-we-on-know-us-page-heading-and-para-div p, .what-we-do-on-know-us-page-heading-and-para-div p {
        line-height: 39px;
        font-size: 18px;
    }
    .who-are-we-section-on-know-us-page-start-here, .what-we-do-section-on-know-us-page-start-here, .people-who-contribute-section-start-here {
        padding: 1rem 1.2rem;
    }
    .know-us-quote-content-first-inner-div {
        position: unset;
    }
    .know-us-quote-content-div h3 {
        font-size: 31px;
    }
    .know-us-quote-section-start-here {
        padding: 0rem 1.2rem 1rem 1.2rem;
    }
    .know-us-quote-content-div h3, .know-us-quote-content-div p {
        text-align: center;
    }
    .know-us-quote-image-div img {
        height: 551px;
    }
    .people-who-contribute-image-div {
        margin: 1rem 0;
    }
}
@media only screen and (max-width: 576px) {
    .who-are-we-on-know-us-page-image-div img, .what-we-do-on-know-us-page-image-div img, .know-us-quote-image-div img {
        height: 321px;
    }
    .who-are-we-section-on-know-us-page-start-here, .what-we-do-section-on-know-us-page-start-here, .people-who-contribute-section-start-here {
        padding: 1rem 0.5rem;
    }
    .people-who-contribute-first-inner-div {
        padding: 0.7rem;
    }
}
@media only screen and (max-width: 425px) {
    .people-who-contribute-first-inner-div p {
        text-align: center;
        font-size: 16.5px;
    }
}
