
.who-are-we-section-start-here, .what-we-do-section-start-here {
    padding: 0 1.2rem;
}

.who-are-we-section-start-here {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.who-are-we-image-div img, .what-we-do-image-div img {
    width: 100%;
    height: 400px;
    object-position: center;
    object-fit: cover;
}

.what-we-do-image-div img {
    height: 75vh;
    object-position: top;
    object-fit: fill;
}

.who-are-we-heading-and-para-div {
    padding-top: 3rem;
}

.who-are-we-heading-and-para-div h2, .what-we-do-heading-and-para-div h2, .our-solutions-heading-div h2 {
    color: rgba(0, 0, 0, 0.7);
    font-weight: 700;
    font-size: 47px;
    line-height: 45px;
    text-align: center;
    padding-bottom: 1.5rem;
}

.who-are-we-heading-and-para-div h2 span, .what-we-do-heading-and-para-div h2 span, .our-solutions-heading-div h2 span {
    color: rgba(187, 61, 39, 0.95);
}

.who-are-we-heading-and-para-div p, .what-we-do-heading-and-para-div p {
    color: rgba(0, 0, 0, 0.85);
    text-align: justify;
    line-height: 41px;
    font-weight: 600;
    font-size: 21px;
}

.who-are-we-heading-and-para-div button, .what-we-do-heading-and-para-div button {
    border: none;
    outline: none;
    background: rgba(187, 61, 39, 0.95);
    color: #FFFFFF;
    font-weight: 700;
    border-radius: 11px;
    padding: 0.5rem 2rem;
    font-size: 21px;
}

.what-we-do-heading-and-para-div {
    padding-top: 5rem;
}

.our-solutions-section-start-here {
    padding: 2.5rem 1.2rem;
}

.our-solutions-heading-div h2 {
    padding-bottom: 4rem;
}

/* Responsive Page CSS Start Here */
@media only screen and (max-width: 991px) {
    .who-are-we-heading-and-para-div h2, .what-we-do-heading-and-para-div h2, .our-solutions-heading-div h2 {
        font-size: 41px;
    }
}
@media only screen and (max-width: 767px) {
    .who-are-we-heading-and-para-div h2, .what-we-do-heading-and-para-div h2, .our-solutions-heading-div h2 {
        font-size: 35px;
    }
    .who-are-we-heading-and-para-div p, .what-we-do-heading-and-para-div p, .who-are-we-heading-and-para-div button, .what-we-do-heading-and-para-div button {
        font-size: 18px;
    }
    .what-we-do-heading-and-para-div {
        padding-top: 1rem;
    }
    .our-solutions-product-div {
        margin-bottom: 1.5rem;
    }
}
@media only screen and (max-width: 576px) {
    .who-are-we-image-div img {
        height: 321px;
    }
    .who-are-we-section-start-here, .what-we-do-section-start-here {
        padding: 0 0.5rem;
    }
    .our-solutions-section-start-here {
        padding: 2.5rem 0.5rem;
    }
}
@media only screen and (max-width: 425px) {
    .who-are-we-image-div img {
        height: 268px;
    }
    .what-we-do-image-div img {
        height: 61vh;
    }
}