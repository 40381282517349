
.events-page-first-section-start-here {
    padding: 0 1.2rem;
}

.events-button-and-image-section-start-here {
    padding: 2.5rem 0rem;
}

.events-page-first-section-start-here {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.events-page-image-div img {
    width: 100%;
    height: 400px;
    object-position: center;
    object-fit: cover;
}

.events-page-heading-and-para-div {
    padding-top: 3rem;
}

.events-page-heading-and-para-div h2 {
    color: rgba(0, 0, 0, 0.7);
    font-weight: 700;
    font-size: 47px;
    line-height: 45px;
    text-align: center;
    padding-bottom: 1.5rem;
}

.events-page-heading-and-para-div h2 {
    color: rgba(187, 61, 39, 0.95);
}

.events-page-heading-and-para-div p {
    color: rgba(0, 0, 0, 0.85);
    text-align: justify;
    line-height: 41px;
    font-weight: 600;
    font-size: 21px;
}

.events-button-div {
    background: rgba(187, 61, 39, 0.95);
    height: auto;
    padding: 1rem 0rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
    /* padding-left: 0rem; */
}

.events-button-div h4 {
    margin: 5rem 0;
}

.events, .csr {
    background: #FFFFFF;
    padding: 1rem 5rem;
    border-radius: 40px 0px 0px 40px;
    color: rgba(187, 61, 39, 0.95);
    font-weight: 700;
    cursor: pointer;
}

.csr {
    padding: 1rem 6.6rem;
}

.without-click-events {
    padding: 1rem 5rem;
}

.without-click-csr {
    padding: 1rem 6.6rem;
}

.without-click-events, .without-click-csr {
    color: #FFFFFF;
    font-weight: 700;
    cursor: pointer;
}

.events-image-div {
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    padding: 0rem 3rem;
}

.events-first-two-images-div, .events-third-fourth-images-div {
    display: flex;
    justify-content: space-around;
    /* flex-direction: column; */
    align-items: center;
}

.events-first-two-images-div img, .events-third-fourth-images-div img {
    margin: 0 2rem;
    width: 372px;
    height: 235px;
    object-position: center;
    object-fit: cover;
    cursor: pointer;   
}

.events-first-two-images-div .top-banner-images {
    margin-bottom: 1rem;
}

.events-third-fourth-images-div .bottom-banner-images {
    margin-top: 1rem;
}

/* * {
    border: 0.5px solid orange;
} */

/* Responsive Page CSS Start Here */
@media only screen and (max-width: 1150px) {
    .events-first-two-images-div img, .events-third-fourth-images-div img {
        margin: 0rem 0rem;
        width: 345px;
    }
}
@media only screen and (max-width: 1072px) {
    .events-first-two-images-div img, .events-third-fourth-images-div img {
        width: 301px;
    }
}
@media only screen and (max-width: 1022px) {
    .csr {
        padding: 1rem 6.3rem;
    }
}
@media only screen and (max-width: 991px) {
    .events-page-image-div img {
        height: 551px;
    }
    .events-page-heading-and-para-div h2 {
        font-size: 41px;
    }
    .events-page-heading-and-para-div {
        padding-top: 1rem;
    }
    .events, .csr {
        border-radius: 0px;
        border-bottom: 3px solid rgba(187, 61, 39, 0.95);
        border-right: 3px solid rgba(187, 61, 39, 0.95);
        border-top: 3px solid rgba(187, 61, 39, 0.95);
        box-shadow: 0 4px 28px rgba(0,0,0,.25);
    }
}
@media only screen and (max-width: 965px) {
    .events-image-div {
        padding: 0rem 1rem;
    }
}
@media only screen and (max-width: 871px) {
    .events-first-two-images-div img, .events-third-fourth-images-div img {
        width: 285px;
    }
}
@media only screen and (max-width: 850px) {
    .events {
        padding: 1rem 4rem;
    }
    .csr {
        padding: 1rem 5.5rem;
    }
    .without-click-events {
        padding: 1rem 4rem;
    }
}
@media only screen and (max-width: 825px) {
    .events-first-two-images-div img, .events-third-fourth-images-div img {
        width: 267px;
        height: 222px;
    }
}
@media only screen and (max-width: 767px) {
    .events-page-heading-and-para-div h2 {
        font-size: 35px;
        padding-bottom: 1rem;
    }
    .events-page-heading-and-para-div p {
        line-height: 37px;
        font-size: 18px;
    }
    .without-click-csr {
        padding: 1rem 5rem;
    }
    .without-click-events, .without-click-csr, .events, .csr {
        font-size: 21px;
    }
    .events-first-two-images-div img, .events-third-fourth-images-div img {
        width: 222px;
    }
}
@media only screen and (max-width: 700px) {
    .events {
        padding: 1rem 3rem;
    }
}
@media only screen and (max-width: 650px) {
    .events-button-div h4 {
        margin: 4rem 0;
    }
    .events-first-two-images-div img, .events-third-fourth-images-div img {
        width: 201px;
        height: 201px;
    }
}
@media only screen and (max-width: 590px) {
    .events-button-div h4 {
        margin: 3rem 0;
    }
    .events-first-two-images-div img, .events-third-fourth-images-div img {
        width: 175px;
        height: 175px;
    }
}
@media only screen and (max-width: 576px) {
    .events-page-first-section-start-here {
        padding: 0 0.5rem;
    }
    .events-page-image-div img {
        height: 321px;
    }
}
@media only screen and (max-width: 575px) {
    .without-click-csr {
        padding: 1rem 3.5rem;
    }
    .events {
        padding: 1rem 2.3rem;
    }
    .csr {
        padding: 1rem 3.5rem;
    }
    .without-click-events {
        padding: 1rem 2rem;
    }
    .without-click-events, .without-click-csr, .events, .csr {
        font-size: 18px;
    }
    .hide-banner-images {
        display: none;
    }
    .events-first-two-images-div img, .events-third-fourth-images-div img {
        width: 401px;
        height: auto;
    }
}
@media only screen and (max-width: 450px) {
    .events {
        padding: 1rem 1.4rem;
    }
    .without-click-csr, .csr {
        padding: 1rem 2.5rem;
    }
    .without-click-events {
        padding: 1rem 1.3rem;
    }
}
@media only screen and (max-width: 400px) {
    .events, .without-click-events {
        padding: 0.5rem 1rem;
    }
    .without-click-events, .without-click-csr, .events, .csr {
        font-size: 16.5px;
    }
    .without-click-csr, .csr {
        padding: 0.5rem 2.1rem;
    }
}
@media only screen and (max-width: 350px) {
    .events, .without-click-events {
        padding: 0.5rem 0.8rem;
    }
    .without-click-csr, .csr {
        padding: 0.5rem 1.8rem;
    }
    .without-click-events, .without-click-csr, .events, .csr {
        font-size: 16px;
    }
}
@media only screen and (max-width: 320px) {
    .events, .without-click-events {
        padding: 0.5rem 0.6rem;
    }
    .without-click-csr, .csr {
        padding: 0.5rem 1.6rem;
    }
    .without-click-events, .without-click-csr, .events, .csr {
        font-size: 16px;
    }
}